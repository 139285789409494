import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";

export const StyledUl = styled.ul`
  display: flex;
  flex-direction: column;
  line-height: 1.4;
`;

export const StyledLi = styled.li`
  padding: 0.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  font-weight: 300;
`;

export const StyledLink = styled(Link)`
  color: rgba(76, 118, 56);
  text-decoration: none;
  font-size: ${(props) => (props.isSubItem ? "1.2rem" : "1.8rem")};
`;

export const StyledMenu = styled(Menu)``;
