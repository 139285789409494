import styled from "styled-components";
import Button from "@mui/material/Button";
import { colors } from "../../styles/variables";

export const StyledModal = styled.div``;

export const StyledButton = styled(Button)`
  color: white !important;
  background-color: #383e42 !important;
  font-size: 1.2rem !important;
`;

export const StyledLetter = styled.span`
  color: ${(props) => (props.isHovered ? `${colors.bovi}` : "white")};
`;
