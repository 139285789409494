import React from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { GlobalStyle } from "./styles/global";

import Landing from "./Pages/Landing";
import Company from "./Pages/Company";
import Contact from "./Pages/Contact";
import Privacy from "./Pages/Privacy";
import Projects from "./Pages/Projects";
import Services from "./Pages/Services";
import Team from "./Pages/Team";
import FAQ from "./Pages/FAQ";
import Employment from "./Pages/Employment";
import MilkAnalysis from "./Pages/MilkAnalysis";
import DownloadSection from "./Pages/DownloadSection";
import Consulting from "./Pages/Consulting";
import InventoryMonitoring from "./Pages/InventoryMonitoring";
import AntibioticReduction from "./Pages/AntibioticReduction";
import AboutUs from "./Pages/AboutUs";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Burger from "./components/Burger";
import Navbar from "./components/Navbar";
import Wrapper from "./components/Wrapper";

import { AppWrapper } from "./styles";

function App() {
  const sm = useMediaQuery("only screen and (max-width: 798px)");
  return (
    <Router>
      <GlobalStyle />
      <AppWrapper>
        <Header content={sm ? <Burger /> : <Navbar />} />
        <Wrapper>
          <Switch>
            <Route exact path="/" component={Landing} />

            {/* Dienstleistungen */}
            <Route path="/employment" component={Employment} />
            <Route path="/milk-analysis" component={MilkAnalysis} />
            <Route path="/consulting" component={Consulting} />
            <Route
              path="/inventory-monitoring"
              component={InventoryMonitoring}
            />
            <Route
              path="/antibiotic-reduction"
              component={AntibioticReduction}
            />
            <Route path="/projects" component={Projects} />

            {/* Service */}
            <Route path="/services" component={Services} />
            <Route path="/downloads" component={DownloadSection} />

            {/* About us */}
            <Route path="/about-us" component={AboutUs} />
            <Route path="/company" component={Company} />
            <Route path="/team" component={Team} />
            <Route path="/contact" component={Contact} />

            {/* Footer */}
            <Route path="/privacy" component={Privacy} />
            <Route path="/faq" component={FAQ} />
          </Switch>
        </Wrapper>
        <Footer />
      </AppWrapper>
    </Router>
  );
}

export default App;
