import React from "react";

import { Headline } from "../Landing/styles";
import ContentSection from "../../components/ContentSection";
import ResponsiveHeader from "../../components/RepsonsiveHeader";
import Paragraph from "../../components/Paragraph";
import Image from "../../components/Image";

import milkAnalysis_1 from "../../assets/milkAnalysis_1.png";
import milkAnalysis_2 from "../../assets/milkAnalysis_2.png";

export default function index() {
  return (
    <ContentSection>
      <ResponsiveHeader />
      <Headline>Milchuntersuchung</Headline>
      <Paragraph>
        Eine stabile Eutergesundheit stellt die Grundlage für eine
        leistungsfähige und langlebige Milchviehherde dar und sichert den
        ökonomischen Erfolg des Betriebes. In diesem Zusammenhang ist die
        Untersuchung von Milchproben ein wichtiges Instrument in der
        Mastitiskontrolle.
      </Paragraph>
      <Paragraph>
        Dafür betreiben wir unser eigenes modern ausgestattetes Mastitislabor in
        dem nach internationalen Standards gearbeitet wird. Neben der
        kulturellen Untersuchung auf Mastitiserreger wird routinemäßig auch der
        exakte Zellgehalt bestimmt.{" "}
      </Paragraph>
      <Image imgSrc={milkAnalysis_1} altText="milkAnalysis_1" width="50%" />
      <Paragraph>
        Daneben können regelmäßige Milchkontrollen mit Bestimmung der
        Milchinhaltstoffe (Fett, Eiweiß, Harnstoff etc.) durchgeführt werden.
      </Paragraph>
      <Paragraph>
        Um etwaige Behandlungsmöglichkeiten zu optimieren, umfasst der
        Untersuchungsumfang ferner die Erstellung von Antibiogrammen zur
        Resistenzprüfung der Mastitiserreger.
      </Paragraph>
      <Image imgSrc={milkAnalysis_2} altText="milkAnalysis_2" width="50%" />
      <Paragraph>
        Die Untersuchungsergebnisse können so in ein strategisches
        Eutergesundheitsmanagement eingebunden werden. Durch die Möglichkeit
        unserer Bestandsbetreuungstierärzte, auf die Ergebnisse der
        Laboruntersuchungen direkt zurückzugreifen, können diese unmittelbar und
        in sehr differenzierter Form in der Beratung genutzt und auf den
        Betrieben umgesetzt werden.
      </Paragraph>
      <Paragraph>
        Hierzu können Sie neben der Möglichkeit, Einzelproben zur Untersuchung
        einzusenden, auch unsere vergünstigten Probenpakete nutzen. Wir bieten
        neben den Basispaketen auch Beratungspakete (bovi3 [Hyperlink]) an, die
        über die Untersuchung der Milchproben hinaus eine tierärztliche
        Kommentierung mit Handlungsempfehlungen umfassen.{" "}
      </Paragraph>
      <Paragraph>
        Für größer angelegte Projekte bieten wir ferner Sondertarife an. Für
        dies bezügliche Anfragen können Sie uns gerne kontaktieren.
      </Paragraph>
      <Paragraph>
        Unsere Preise entnehmen Sie bitte der aktuellen{" "}
        <a href="./downloads">Preisliste</a>
      </Paragraph>
    </ContentSection>
  );
}
