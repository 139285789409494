import styled from "styled-components/macro";
import { mediaTypes } from "../../styles/utils";
import { colors } from "../../styles/variables";

export const Wrapper = styled.div``;

export const Teaser = styled.div`
  display: flex;
  border-left: 7px solid ${colors.purple};
  padding-left: 15px;
  margin-bottom: 30px;
  margin-top: 30px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
`;

export const Title = styled.h1`
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 5px;
  font-size: 45px;
  color: white;
  font-weight: bold;
  background-color: ${colors.bovi};
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  @media ${mediaTypes.md} {
    flex-direction: row;
    align-items: start;
  }
`;

export const Headline = styled.h1`
  color: white;
  font-weight: 300;
  font-size: 1.8rem;
  font-weight: ${(props) => (props.isStrong ? "bold" : "normal")};
`;

export const Description = styled.p`
  color: white;
  font-weight: 300;
  text-align: left;
  text-shadow: 1px 1px black;
  @media ${mediaTypes.md} {
    font-size: 1.2rem;
    text-align: ${(props) => (props.center ? "center" : "left")};
    max-width: ${(props) => (props.center ? "100%" : "75%")};
    font-weight: 400;
  }
`;

export const Flex = styled.div`
  display: flex;
  padding-left: 1rem;
  white-space: nowrap;
`;

export const StyledA = styled.a`
  color: white;
`;
