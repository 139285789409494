import styled from "styled-components/macro";
import { mediaTypes } from "../../styles/utils";

export const ContactWrapper = styled.div`
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  height: 25rem;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Title = styled.h1`
  color: rgba(76, 118, 56);
  padding-left: 5%;
  font-weight: 300;
`;

export const SubTitle = styled.h2`
  color: white;
  padding-left: 5%;
  font-weight: 300;
`;

export const CardTitle = styled.h3`
  color: white;
  font-weight: 300;
`;

export const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @media ${mediaTypes.sm} {
    flex-direction: row;
  }
  @media ${mediaTypes.md}{
    width: 65%;
  }
`;

export const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #383e42;
  @media ${mediaTypes.md} {
    width: 49%;
  }
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${mediaTypes.md} {
    justify-content: flex-end;
  }
`;

export const Image = styled.img`
  margin-right: 1rem;
`;

export const MapImage = styled.div`
  width: 100%;
  padding-top: 1rem;
  @media ${mediaTypes.md} {
    width: 49%;
    height: unset;
    padding-top: 0;
  }
`;
