import React from "react";

import ResponsiveHeader from "../../components/RepsonsiveHeader";

const AboutUs = () => {
  return (
    <>
      <ResponsiveHeader />
      <div>AboutUs</div>
    </>
  );
};

export default AboutUs;
