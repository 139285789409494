import { css, createGlobalStyle } from "styled-components";
import { colors, font } from "./variables";
import { mediaTypes } from "./utils";

import fonts from "./fonts";

export const fontStyleBase = css`
  color: ${font.color.text};
  font-family: ${font.type.regular};
  font-weight: ${font.weight.regular};
  font-style: normal;
`;

export const GlobalStyle = createGlobalStyle`
html {
  width: 100%;
  height: 100%;
}
html, body {
  margin: 0;
  font-size: 100%;
  @media ${mediaTypes.ipad} {
    font-size: 110%;
    }
  @media ${mediaTypes.xxl} {
    font-size: 120%;
    }
  }
  ${fonts}

  body {
    margin: 0;
    padding: 0;
    ${fontStyleBase}
  }

  strong {
    font-family: ${font.type.semibold};
    font-weight: ${font.weight.semibold};
  }


  highlighted {
    color: ${colors.yellow};
    font-family: ${font.type.bold};
    font-weight: ${font.weight.bold};
  }

  quote {
    font-style: italic;
    font-size: .8rem;
  }

  referent {
    font-size: .8rem;
  }

  sup {
    line-height: 0;
    font-size: 0.8em;
    vertical-align: super;
  }

  #root {
  }
`;
