import React, { useState } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";

import Card from "../Card";
import { StyledModal, StyledButton, StyledLetter } from "./styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#383e42",
  p: 4,
  borderRadius: "25px",
};

const ModalComponent = () => {
  const [open, setOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <StyledModal>
      <StyledButton
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleOpen}
      >
        <StyledLetter isHovered={isHovered}>K</StyledLetter>ontakt
      </StyledButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Card />
        </Box>
      </Modal>
    </StyledModal>
  );
};

export default ModalComponent;
