import React, { useState } from "react";

import { StyledMenu, StyledUl, StyledLi, StyledLink } from "./styles";

const Burger = () => {
  const [showMenu, setMenu] = useState(false);
  const openCard = () => {
    setMenu(false);
  };
  return (
    <StyledMenu
      onOpen={() => setMenu(true)}
      onClose={() => setMenu(false)}
      isOpen={showMenu}
      right
    >
      <StyledLi onClick={() => openCard()}>
        <StyledLink to="/">Startseite</StyledLink>
      </StyledLi>
      <StyledLi onClick={() => openCard()}>
        <StyledLink to="/milk-analysis">Dienstleistungen</StyledLink>
        <StyledUl>
          <StyledLink isSubItem to="/milk-analysis">Milchuntersuchungen</StyledLink>
          <StyledLink isSubItem to="/consulting">Beratung</StyledLink>
          <StyledLink isSubItem to="/inventory-monitoring">Bestandsbegleitung</StyledLink>
          <StyledLink isSubItem to="/antibiotic-reduction">
            Antibiotikareduzierung
          </StyledLink>
          <StyledLink isSubItem to="/projects">Projekte</StyledLink>
        </StyledUl>
      </StyledLi>
      <StyledLi onClick={() => openCard()}>
        <StyledLink to="/downloads">Service</StyledLink>
        <StyledUl>
          <StyledLink isSubItem to="/downloads">Downloads</StyledLink>
        </StyledUl>
      </StyledLi>
      <StyledLi onClick={() => openCard()}>
        <StyledLink to="/company">Über uns</StyledLink>
        <StyledUl>
          <StyledLink isSubItem to="/company">Unternehmen</StyledLink>
          <StyledLink isSubItem to="/contact">Kontakt</StyledLink>
          <StyledLink isSubItem to="/team">Team</StyledLink>
        </StyledUl>
      </StyledLi>
    </StyledMenu>
  );
};

export default Burger;
