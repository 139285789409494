import styled from "styled-components/macro";
import { mediaTypes } from "../../styles/utils";

export const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 83vh;
  @media ${mediaTypes.sm} {
    min-height: 92vh;
  }
  @media ${mediaTypes.md} {
    display: flex;
    align-items: center;
  }
  @media ${mediaTypes.lg} {
    min-height: 83vh;
  }
  @media ${mediaTypes.xl} {
  }
`;
