import React from "react";

import { Wrapper } from "../Landing/styles";

import { Teaser, Headline, Description } from "../Landing/styles";

import { SubHeader, Section, Download, Icon } from "./styles";

import pdfIcon from "../../assets/pdf.png";

import Preislist from "../../assets/pdf/Preisliste_220501.pdf";
import Neukunde from "../../assets/pdf/M05_Material_Erstausstattung.pdf";
import Untersuchungsauftrag from "../../assets/pdf/M07_Untersuchungsauftrag_bovicare_blanko.pdf";
import BeratungsInfo1 from "../../assets/pdf/M00_bovi3_Uebersicht.pdf";
import Richtungsbefund from "../../assets/pdf/M09_Richtungsbefund.pdf";
import BeratungsInfo2 from "../../assets/pdf/M04_bovicare_selektives_Trockenstellen.pdf";
import BeratungsInfo3 from "../../assets/pdf/M08_Zitzenversiegler.pdf";
import BeratungsInfo4 from "../../assets/pdf/M06_Trockensteller_antibiotisch.pdf";

export default function index() {
  return (
    <Wrapper>
      <Teaser>
        <Headline>Downloads Dokumente</Headline>
        <Description>Alles Wissenswerte zum herunterladen!</Description>
        <Download href={Preislist} target="_blank">
          <Section>
            <SubHeader>Preisliste</SubHeader>
            <Icon src={pdfIcon} />
          </Section>
        </Download>
        <Download href={Neukunde} target="_blank">
          <Section>
            <SubHeader>Neukunde</SubHeader>
            <Icon src={pdfIcon} />
          </Section>
        </Download>
        <Download href={Untersuchungsauftrag} target="_blank">
          <Section>
            <SubHeader>Untersuchungsauftrag</SubHeader>
            <Icon src={pdfIcon} />
          </Section>
        </Download>
        <Download href={BeratungsInfo1} target="_blank">
          <Section>
            <SubHeader>Bovi3 Konzept</SubHeader>
            <Icon src={pdfIcon} />
          </Section>
        </Download>
        <Download href={Richtungsbefund} target="_blank">
          <Section>
            <SubHeader>Richtungsbefund</SubHeader>
            <Icon src={pdfIcon} />
          </Section>
        </Download>
        <Download href={BeratungsInfo2} target="_blank">
          <Section>
            <SubHeader>Selektives Trockenstellen</SubHeader>
            <Icon src={pdfIcon} />
          </Section>
        </Download>
        <Download href={BeratungsInfo3} target="_blank">
          <Section>
            <SubHeader>Zitzenversiegler</SubHeader>
            <Icon src={pdfIcon} />
          </Section>
        </Download>
        <Download href={BeratungsInfo4} target="_blank">
          <Section>
            <SubHeader>Trockensteller Antibiotisch</SubHeader>
            <Icon src={pdfIcon} />
          </Section>
        </Download>
      </Teaser>
    </Wrapper>
  );
}
