import styled from "styled-components/macro";
import { colors } from "../styles/variables";

export const PageWrapper = styled.div`
  height: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
  align-items: center;
  position: relative;
  justify-content: space-between;
  /*height: 82%;*/
  background-color: ${colors.white};
`;

export const ContentSection = styled.div`
  width: 90%;
  margin-left: auto;
  margin-right: auto;
`;

export const CenterSection = styled.div`
  display: flex;
  justify-content: center;
`;

export const Title = styled.h1`
  color: white;
  font-size: 1.6rem;
`;

export const SubTitle = styled.h2`
  color: white;
  font-size: 1.3rem;
`;

export const StyledParagraph = styled.p`
  color: white;
  font-size: 1rem;
`;
