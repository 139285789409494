export const colors = {
  // Base colors
  white: "#ffffff",
  black: "#000000",
  teal: "#2A7475",
  grey: "#818387",
  yellow: "#FED141",
  darkGrey: "#63666A",
  mediumGrey: "#888B8D",
  lightGrey: "#B1B3B3",
  teal20: "#CCEDEB",
  darkGrey20: "#DBDBDB",
  background: "#F2F2F2",
  darkGreen: "#3a7374",
  green: "#BDDC04",
  green20: "#eff4d7",
  aqua: "#00B0B9",
  purple: "#7D83DB",
  bovi: "#3C652A",
};

export const font = {
  color: {},
  type: {
    regular: "Karbon Regular",
    semibold: "Karbon Semibold",
    bold: "Karbon Bold",
  },
  style: {
    normal: "normal",
    italic: "italic",
  },
  weight: {
    bold: "700",
    semibold: "600",
    regular: "400",
  },
};