import React from "react";
import Dropdown from "../Dropdown";

import { NavWrapper, Logo, StyledUl, StyledLi, StyledLink } from "./styles";
import bovicare from "../../assets/logo.png";

export default function index() {
  return (
    <NavWrapper>
      <Logo src={bovicare} alt="logo" />
      <StyledUl>
        <StyledLi>
          <StyledLink to={"/"}>Startseite</StyledLink>
        </StyledLi>
        <StyledLi>
          <Dropdown
            dropdownTitle="Dienstleistungen"
            firstDropdownElement="Milchuntersuchungen"
            firstDropdownLink={"/milk-analysis"}
            secondDropdownElement="Beratung"
            secondDropdownLink={"/consulting"}
            thirdDropdownElement="Bestandsbegleitung"
            thirdDropdownLink={"/inventory-monitoring"}
            fourthDropdownElement="Antibiotikareduzierung"
            fourthDropdownLink={"/antibiotic-reduction"}
            fifthDropdownElement="Projekte"
            fifthDropdownLink={"/projects"}
          />
        </StyledLi>
        <StyledLi>
          <Dropdown
            dropdownTitle="Service"
            firstDropdownElement="Downlaods"
            firstDropdownLink={"/downloads"}
          />
        </StyledLi>
        <StyledLi>
          <Dropdown
            dropdownTitle="Über uns"
            firstDropdownElement="Unternehmen"
            firstDropdownLink={"/company"}
            secondDropdownElement="Kontakt"
            secondDropdownLink={"/contact"}
            thirdDropdownElement="Team"
            thirdDropdownLink={"/team"}
          />
        </StyledLi>
      </StyledUl>
    </NavWrapper>
  );
}
