import React from "react";
import { useMediaQuery } from "@react-hook/media-query";

import { Title } from "../styles";
import { Title as LandingTitle } from "../Landing/styles";

import Card from "../../components/Card";

import { Flex, ContactWrapper, ContactCard, LeftContainer, MapImage } from "./styles";

export const Contact = () => {
  const sm = useMediaQuery("only screen and (max-width: 798px)");
  return (
    <ContactWrapper>
      {sm && <LandingTitle>bc</LandingTitle>}
      <Title>So können Sie uns erreichen!</Title>
      <Flex>
        <ContactCard>
          <LeftContainer>
            <Card />
          </LeftContainer>
          <MapImage>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2419.137228017557!2d13.538366616528196!3d52.67555967984397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a9b24bbf1add69%3A0xbb6287d6a82f6cb7!2sBernauer%20Allee%2010%2C%2016321%20Bernau%20bei%20Berlin!5e0!3m2!1sen!2sde!4v1630229596828!5m2!1sen!2sde"
              title="bovicareLocation"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </MapImage>
        </ContactCard>
      </Flex>
    </ContactWrapper>
  );
}

export default Contact;