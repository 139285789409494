import styled from "styled-components";
import { mediaTypes } from "../../styles/utils";

export const Wrapper = styled.div`
  
`;

export const Download = styled.a`
  text-decoration: none;
`;

export const StyledA = styled.a`
  text-decoration: none;
  &:visited {
    text-decoration: none;
    color: rgb(0, 0, 238);
  }
`;

export const StyledUl = styled.ul`
  color: black;
  font-weight: 300;
  text-align: left;
`;

export const Headline = styled.h1`
  color: black;
  font-size: 1.8rem;
`;

export const Description = styled.p`
  color: black;
  font-weight: 300;
  text-align: left;
  @media ${mediaTypes.md} {
    font-size: 1.2rem;
    text-align: ${(props) => (props.center ? "center" : "left")};
    max-width: ${(props) => (props.center ? "100%" : "75%")};
    font-weight: 400;
  }
`;