import styled from "styled-components";
import { mediaTypes } from "../../styles/utils";

export const StyledCard = styled.div``;

export const ContactFlex = styled.div`
  display: flex;
  padding-left: 1rem;
`;

export const Image = styled.img`
  margin-right: 1rem;
`;

export const CardTitle = styled.h3`
  color: white;
  font-weight: 300;
`;

export const SubTitle = styled.h2`
  color: white;
  padding-left: 20px;
  font-weight: 300;
`;

export const ContactCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${mediaTypes.sm} {
    flex-direction: row;
  }
`;

export const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  @media ${mediaTypes.sm} {
    width: 49%;
  }
`;

export const MapImage = styled.div`
  width: 80%;
  padding-top: 1rem;
  padding: 30px;
  @media ${mediaTypes.sm} {
    width: 40%;
    height: unset;
  }
`;

export const StyledA = styled.a`
  color: white;
`;