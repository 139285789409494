import React from "react";

import { Download, StyledA, Wrapper, StyledUl, Description, Headline } from "./styles";

import BeratungsInfo1 from "../../assets/pdf/M00_bovi3_Uebersicht.pdf";

const Employment = () => {
  return (
    <Wrapper>
      <Headline>bovi3 – Eutergesundheitsmanagement auf neue Art</Headline>
      <Description>
        bovi3 ist ein Beratungs-Konzept, das die bovicare GmbH 2013 für
        Milchviehhalter entwickelt hat und immer weiter entwickelt. Dabei nutzt
        es alle zur Verfügung stehenden Informationen aus dem Betrieb, um
        gezielte Vorgehensweisen beim Eutergesundheitsmanagement
        betriebsindividuell umzusetzen.
        <br /> <br />
        Das bovi3-Paket kann von allen interessierten Milchviehhaltern gebucht
        werden. Je gebuchtem Paket erhält der Landwirt folgende Komponenten:
      </Description>
      <StyledUl>
        <li>
          Milchprobenröhrchen mit Vorbeschriftung der Viertel und mehrfarbigen
          Stopfen.
        </li>
        <li>
          Probennahme-Material in ausreichender Menge (Zellstoff, Alkohol zur
          Desinfektion)
        </li>
        <li>
          Versandmaterial (wattierte Umschläge in gewünschter Größe,
          betriebsindividuelle Adressaufkleber; Achtung: Porto muss selbst
          entrichtet werden!)
        </li>
        <li>
          Dokumentationsmaterial (Eddingstift, betriebsspezifische
          Untersuchungsauftrags-Formulare).
        </li>
        <li>100 bakteriologische Milchuntersuchungen und Zellzahlmessungen.</li>
        <li>
          Antibiogramme bei mastitisrelevanten Erregern, die eine Behandlung
          nach sich ziehen können.
        </li>
        <li>
          Versand der Probenergebnisse an Landwirt und Hoftierarzt per Email
          innerhalb von 48 bis 72 Stunden nach Probeneingang.
        </li>
        <li>Exklusiv im bovi3-Paket:</li>
        <StyledUl>
          <li>
            Tierärztliche Kommentierung aller Milchproben der
            Untersuchungsergebnisse mit möglichen Behandlungsempfehlungen.
          </li>
          <li>
            Richtungsbefunde nach 24 Stunden mit Hinweisen auf mögliche Erreger
            und bereits definitiver Zellzahl .
          </li>
          <li>
            Bei elektronischer Bereitstellung von Daten der
            Milchleistungsprüfung durch den Landwirt oder den
            Landeskontrollverband (derzeit: ADS-Format oder HERDE-Sicherung)
            monatliche, spezifische Berichte und Herdenlisten.
          </li>
        </StyledUl>
      </StyledUl>
      <Download href={BeratungsInfo1} target="_blank">
        Weitere Informationen entnehmen Sie unserem Merkblatt.
      </Download>
      <Description>
        Wir erstellen Ihnen gerne ein individuelles Angebot und beraten Sie
        gerne auch zu anderen Paketangeboten oder zu einem für Sie passenden
        strategischen Probenmanagement.{" "}
      </Description>
      <Description>
        Besuchen Sie auch unsere{" "}
        <StyledA href="/services">Service-Seite</StyledA>, von der Sie einige
        Dokumente zu diesem Thema herunterladen können.{" "}
      </Description>
      <Description>
        Kontakt:{" "}
        <StyledA href="mailto:kontakt@bovicare.de">kontakt@bovicare.de</StyledA>
      </Description>
    </Wrapper>
  );
};

export default Employment;
