import React from "react";

import {
  DownloadContentSection,
  DownloadElement,
  DownloadTitle,
  LeftContainer,
  MidContainer,
  RightContainer,
  DownloadA,
} from "./styles";

import ContentSection from "../../components/ContentSection";
import ResponsiveHeader from "../../components/RepsonsiveHeader";

import Preislist from "../../assets/pdf/Preisliste_220501.pdf";
import Neukunde from "../../assets/pdf/M05_Material_Erstausstattung.pdf";
import Untersuchungsauftrag from "../../assets/pdf/M07_Untersuchungsauftrag_bovicare_blanko.pdf";
import BeratungsInfo1 from "../../assets/pdf/M00_bovi3_Uebersicht.pdf";
import Richtungsbefund from "../../assets/pdf/M09_Richtungsbefund.pdf";
import BeratungsInfo2 from "../../assets/pdf/M04_bovicare_selektives_Trockenstellen.pdf";
import BeratungsInfo3 from "../../assets/pdf/M08_Zitzenversiegler.pdf";
import BeratungsInfo4 from "../../assets/pdf/M06_Trockensteller_antibiotisch.pdf";

import { Title } from "../styles";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const DownloadSection = () => {
  return (
    <ContentSection>
      <ResponsiveHeader />
      <Title>Alles Wissenswerte zum herunterladen</Title>
      <DownloadContentSection>
        <LeftContainer>
          <DownloadA href={Preislist} target="_blank">
            <DownloadElement>
              <DownloadTitle>Preisliste</DownloadTitle>
              <PictureAsPdfIcon color="white" />
            </DownloadElement>
          </DownloadA>
          <DownloadA href={Neukunde} target="_blank">
            <DownloadElement>
              <DownloadTitle>Neukunde</DownloadTitle>
              <PictureAsPdfIcon color="white" />
            </DownloadElement>
          </DownloadA>
          <DownloadA href={Untersuchungsauftrag} target="_blank">
            <DownloadElement>
              <DownloadTitle>Untersuchungsauftrag</DownloadTitle>
              <PictureAsPdfIcon color="white" />
            </DownloadElement>
          </DownloadA>
        </LeftContainer>
        <MidContainer>
          <DownloadA href={BeratungsInfo1} target="_blank">
            <DownloadElement>
              <DownloadTitle>Bovi3 Konzept</DownloadTitle>
              <PictureAsPdfIcon color="white" />
            </DownloadElement>
          </DownloadA>
          <DownloadA href={Richtungsbefund} target="_blank">
            <DownloadElement>
              <DownloadTitle>Richtungsbefund</DownloadTitle>
              <PictureAsPdfIcon color="white" />
            </DownloadElement>
          </DownloadA>
          <DownloadA href={BeratungsInfo2} target="_blank">
            <DownloadElement>
              <DownloadTitle>Selektives Trockenstellen</DownloadTitle>
              <PictureAsPdfIcon color="white" />
            </DownloadElement>
          </DownloadA>
        </MidContainer>
        <RightContainer>
          <DownloadA href={BeratungsInfo3} target="_blank">
            <DownloadElement>
              <DownloadTitle>Zitzenversiegler</DownloadTitle>
              <PictureAsPdfIcon color="white" />
            </DownloadElement>
          </DownloadA>
          <DownloadA href={BeratungsInfo4} target="_blank">
            <DownloadElement>
              <DownloadTitle>Trockensteller Antibiotisch</DownloadTitle>
              <PictureAsPdfIcon color="white" />
            </DownloadElement>
          </DownloadA>
        </RightContainer>
      </DownloadContentSection>
    </ContentSection>
  );
};

export default DownloadSection;
