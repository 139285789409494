import styled from "styled-components";

export const StyledOl = styled.ol`
  color: white;
  font-size: 1.2rem;
`;

export const StyledLi = styled.li`
  padding-top: 1rem;
  padding-bottom: 0.5rem;
`;
