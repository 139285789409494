import styled from "styled-components/macro";
import { mediaTypes } from "./styles/utils";

import background from "./assets/slate_copy.jpg";
import cow from './assets/cow_cropped.png';

export const TopSpace = styled.div`
  width: 100%;
  background-color: white;
`;

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${background});
  width: 100%;
  position: absolute;
  background-size: ${mediaTypes.mdMax ? "cover" : "100%"};
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  z-index: -1;
`;

export const FooterImage = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background: url(${cow});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 40%;
  height: 45%;
`;
