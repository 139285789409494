import React from "react";

import { StyledWrapper } from "./styles";

export default function index({ children }) {
  return (
    <>
      <StyledWrapper>{children}</StyledWrapper>
    </>
  );
}
