import React from "react";

import { Headline } from "../Landing/styles";
import ContentSection from "../../components/ContentSection";
import ResponsiveHeader from "../../components/RepsonsiveHeader";
import Paragraph from "../../components/Paragraph";

export default function index() {
  return (
    <ContentSection>
      <ResponsiveHeader />
      <Headline>Zott-NMM</Headline>
      <Headline isStrong>"Natürlich mehr Milch"</Headline>
      <Paragraph>
        Antibiotika spielen in der Nutztierhaltung eine nicht unerhebliche
        Rolle. Verbraucher reagieren zunehmend kritisch bezüglich ihres
        Einsatzes bei lebensmittelliefernden Tieren. Auch in der
        Milchviehhaltung werden Antibiotika eingesetzt, zum Teil
        notwendigerweise, zum Teil aber zu ungezielt. Die bovicare GmbH widmete
        sich diesem Thema von Anfang an. Ziel ist es, in unseren Betrieben
        Medikamente jeglicher{" "}
        <strong> Art so häufig wie nötig und so wenig wie möglich</strong>
        einzusetzen. Das gilt auch für die antibakterielle Bekämpfung und
        Kontrolle von Infektionskrankheiten, wie der Euterentzündung der
        Milchkuh.
      </Paragraph>
      <Paragraph>
        Seit Anfang des Jahres 2013 hat die Genuss-Molkerei Zott in Mertingen
        das Thema aufgegriffen und zusammen mit der Firma bovicare das Projekt
        „Natürlich mehr Milch“ zunächst in Mittelfranken gestartet. In
        mittlerweile 160 Milchviehbetrieben mit über 11.000 Kühen werden die
        Landwirte beraten und permanent von sachkundigen Beratungstierärzten in
        Verbindung mit den Hof-Veterinären betreut. Es geht darum, die
        Gesundheit der Tiere möglichst lückenlos zu erfassen und auf dieser
        Grundlage Entscheidungen über mögliche Behandlungen oder deren
        Unterlassung zu treffen. Die Landwirte erhalten aus dem
        bakteriologischen Labor und mittels detaillierter Datenauswertungen der
        Milchleistungsprüfung ständig neue Informationen über die Entwicklung
        der Herde und können so rechtzeitig gegensteuern, wenn dies notwendig
        wird oder die optimale Entscheidung für den Einsatz von Medikamenten
        fällen. Hierzu wurden spezielle Werkzeuge zur genauen und schnellen
        Datenauswertung entwickelt und eingeführt. Regelmäßige Treffen in
        Kleingruppen vertiefen das neue Knowhow und dienen dazu, eine „zweite
        Meinung“ der landwirtschaftlichen Kollegen einzuholen.
      </Paragraph>
      <Paragraph>
        Nach nun 9 Jahren Projektlaufzeit zeigen die Daten eine allgemeine
        Verbesserung der Eutergesundheit und eine deutliche Reduzierung des
        Antibiotikaeinsatzes hin. Die Resonanz bei den Landwirten,{" "}
        <strong>„natürlich mehr Milch“</strong> zu produzieren, ist überaus
        groß. Dank der Initiative und der finanziellen Förderung des Projektes
        durch die Genuss-Molkerei Zott kann das Vorhaben als großer Erfolg
        verbucht werden.
      </Paragraph>
      <Paragraph>
        <strong>
          Kontakt: Dr. Peter Klocke
          <br /> beratung@bovicare.de
        </strong>
      </Paragraph>
    </ContentSection>
  );
}
