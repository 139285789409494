import styled from "styled-components";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { colors } from "../../styles/variables";

export const StyledButton = styled(Button)`
  color: white !important;
  font-size: 1.2rem!important;
  &:hover {
    border-left: 3px solid ${colors.purple};
    font-weight: bold;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
  padding: 10px;
  &:hover {
    border-left: 3px solid ${colors.purple};
    font-weight: bold;
  }
`;
