export const mediaTypes = {
    sm: 'screen and (min-width: 600px)',
    md: 'screen and (min-width: 798px)',
    mdMax: 'screen and (max-width: 992px)',
    lg: 'screen and (min-width: 992px)',
    xl: 'screen and (min-width: 1200px)',
    oxl: 'only screen and (min-width: 1200px)',
    xlMax: 'screen and (max-width: 1200px)',
    xxl: 'screen and (min-width: 1440px)',
    xxxl: 'screen and (min-width: 1920px)',
    ipad: 'screen and (min-width: 1024px)',
    port: 'screen and (orientation: portrait)',
  };
  