import { css } from "styled-components";

import { font } from "./variables";

import KarbonRegular from "../assets/fonts/Karbon-Regular.otf";
import KarbonSemibold from "../assets/fonts/Karbon-Semibold.otf";
import KarbonBold from "../assets/fonts/Karbon-Bold.otf";

import KarbonRegularEOT from "../assets/fonts/Karbon-Regular.eot";
import KarbonSemiboldEOT from "../assets/fonts/Karbon-Semibold.eot";
import KarbonBoldEOT from "../assets/fonts/Karbon-Bold.eot";

import KarbonRegularWOFF from "../assets/fonts/Karbon-Regular.eot";
import KarbonSemiboldWOFF from "../assets/fonts/Karbon-Semibold.eot";
import KarbonBoldWOFF from "../assets/fonts/Karbon-Bold.eot";

import KarbonRegularWOFF2 from "../assets/fonts/Karbon-Regular.woff2";
import KarbonSemiboldWOFF2 from "../assets/fonts/Karbon-Semibold.woff2";
import KarbonBoldWOFF2 from "../assets/fonts/Karbon-Bold.woff2";

export default css`
  @font-face {
    font-family: "Karbon Regular";
    font-style: normal;
    font-weight: ${font.weight.regular};
    src: url(${KarbonRegularEOT});
    src: url(${KarbonRegularEOT}&#iefix) format("embedded-opentype"),
      url(${KarbonRegularWOFF}) format("woff"),
      url(${KarbonRegularWOFF2}) format("woff2"),
      url(${KarbonRegular}) format("opentype");
  }

  @font-face {
    font-family: "Karbon Semibold";
    font-style: normal;
    font-weight: ${font.weight.semibold};
    src: url(${KarbonSemiboldEOT});
    src: url(${KarbonSemiboldEOT}&#iefix) format("embedded-opentype"),
      url(${KarbonSemiboldWOFF}) format("woff"),
      url(${KarbonSemiboldWOFF2}) format("woff2"),
      url(${KarbonSemibold}) format("opentype");
  }

  @font-face {
    font-family: "Karbon Bold";
    font-style: normal;
    font-weight: ${font.weight.bold};
    src: url(${KarbonBold});
    src: url(${KarbonBoldEOT}&#iefix) format("embedded-opentype"),
      url(${KarbonBoldWOFF}) format("woff"),
      url(${KarbonBoldWOFF2}) format("woff2"),
      url(${KarbonBold}) format("opentype");
  }
`;
