import React, { useState } from "react";
import Menu from "@mui/material/Menu";

import { StyledButton, StyledLink } from "./styles";

const Dropdown = ({
  dropdownTitle,
  firstDropdownElement,
  firstDropdownLink,
  secondDropdownElement,
  secondDropdownLink,
  thirdDropdownElement,
  thirdDropdownLink,
  fourthDropdownElement,
  fourthDropdownLink,
  fifthDropdownElement,
  fifthDropdownLink,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {dropdownTitle}
      </StyledButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {firstDropdownElement && (
          <StyledLink to={firstDropdownLink} onClick={handleClose}>
            {firstDropdownElement}
          </StyledLink>
        )}
        {secondDropdownElement && (
          <StyledLink to={secondDropdownLink} onClick={handleClose}>
            {secondDropdownElement}
          </StyledLink>
        )}
        {thirdDropdownElement && (
          <StyledLink to={thirdDropdownLink} onClick={handleClose}>
            {thirdDropdownElement}
          </StyledLink>
        )}
        {fourthDropdownElement && (
          <StyledLink to={fourthDropdownLink} onClick={handleClose}>
            {fourthDropdownElement}
          </StyledLink>
        )}
        {fifthDropdownElement && (
          <StyledLink to={fifthDropdownLink} onClick={handleClose}>
            {fifthDropdownElement}
          </StyledLink>
        )}
      </Menu>
    </div>
  );
};

export default Dropdown;
