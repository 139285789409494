import styled from "styled-components";

export const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
  align-items: center;
  padding-top: 15px;
`;

export const SubHeader = styled.h2`
  color: rgba(76, 118, 56);
`;

export const Icon = styled.img`
  width: 50px;
  height: 50px;
`;

export const Download = styled.a`
  text-decoration: none;
`;
