import React from "react";
import { Headline } from "../Landing/styles";

import ContentSection from "../../components/ContentSection";
import ResponsiveHeader from "../../components/RepsonsiveHeader";

export default function index() {
  return (
    <ContentSection>
      <ResponsiveHeader />
      <Headline>Das Unternehmen</Headline>
      {/* <Description>
        Die bovicare GmbH ist ein 2008 gegründetes Dienstleistungsunternehmen,
        das sich mit der Förderung der Gesundheit von Milchkühen beschäftigt. Es
        besteht aus drei Bereichen, dem Laborbetrieb, der tierärztlichen Praxis
        und dem tierärztlichen Beratungs- und Projektbereich. Alle drei
        Abteilungen arbeiten aber eng miteinander verwoben.
        <br /> <br />
        Das Unternehmen entstand im Jahre 2008 als Spinnoff der Freien
        Universität Berlin im Rahmen eines Start-Up-Wettbewerbs. Zunächst in der
        FU angesiedelt konnten 2009 Labor- und Büroräume in Potsdam
        Hermannswerder angemietet werden. Von dort aus etablierte sich der
        Laborbetrieb von anfangs einigen Hundert bis heute auf 50 bis 60.000
        Milchproben jährlich.
        <br /> <br />
        Nachdem sich Potsdam als Standort logistisch und bezüglich der
        Infrastruktur zunehmend schwieriger gestaltete, entschieden wir uns
        2020, das Angebot des Instituts für Nutztierforschung (IFN Schönow)
        anzunehmen und dortige Labor- und Büroräume zu beziehen. Dieser Schritt
        verbesserte nicht nur das Probenmanagement durch verbesserte
        Lieferungs-Anbindung, sondern schaffte uns auch eine höhere Flexibilität
        bei allen Abläufen.
        <br /> <br />
        Im Sommer 2020 war es soweit, dass der Umzug erfolgen konnte. Kurze Zeit
        danach etablierte sich das heutige Team, so dass wir für unsere Kunden
        einen fast reibungslosen Übergang schaffen konnten. <br /> <br />
        Von Anfang an erhielt die bovicare GmbH eine Zulassung als Tierärztliche
        Praxis. So konnte im Raum Brandenburg die Nähe zu den Betrieben und das
        Verständnis für die Problematiken in Milchviehhaltungen gewahrt bleiben.{" "}
        <br /> <br />
        Die zunehmende Bekanntheit in der Branche in Verbindung mit dem Konzept,
        Labor-Expertise mit tierärztlicher Beratung zu verbinden, führte zur
        Expansions des Unternehmes über den ländlichen Raum in Brandenburg
        hinaus. So entwickelten sich langfristige wie kurzzeitige
        Beratungsprojekte in Russland, Polen und Süddeutschland, sowie ein stark
        ansteigender Kundenkreis aus allen neuen Bundesländern,
        Schleswig-Holstein, NIedersachsen, Nordrhein-Westfalen und anderen.{" "}
        <br /> <br />
        Aus dem bundesdeutschen Bereich wird im eigenen Labor nicht nur die
        Milch auf bakteriologisch relevante Erreger untersucht, sondern durch
        Modernisierung der Laboreinrichtung auch die Milchleistungsprüfung auf
        Milchinhaltsstoffe durchgeführt. <br /> <br />
        Die langjährige tierärztliche Erfahrung in Biobetrieben aus dem
        bovicare-Team mündete 20xx in die Aufnahme in das tierärztliche
        Berater-Team der Bioland-Beratung.
        <br /> <br />
        Wir sind Mitglieder in verschiedenen Interessenverbänden und Gremien der
        Branche und leisten dort mit unseren Erfahrungen einen Beitrag.
        <br /> <br />
        Durch langjährige Mitarbeit in verschiedenen Forschungseinrichtungen (FU
        Berlin, Forschungsinstitut für Biologischen Landbau Schweiz und
        Deutschland, Institut für Nutztierforschung) sind wir an diversen
        Forschungsprojekten beteiligt und bieten unseren Zugang zu betrieblichen
        Partnerschaften auch im Rahmen von Forschungsprojekten an. Ergänzt wird
        diese Aktivität durch aus unseren eigenen Forschungstätigkeiten
        gewachsenen umfangreichen Kenntnisse im Bereich des Studiendesigns bei
        Feldforschungsprojekten.
        <br /> <br />
        Bedingt durch unsere Nähe zu bestehenden Forschungseinrichtungen und
        Hochschulen bestehen weiterhin Lehraufträge zum Thema Tiergesundheit.
        Ferner sind wir auf vielen Veranstaltungen wissenschaftlicher Art sowie
        bei landwirtschaftlichen und tierärztlichen Gruppierungen mit Vorträgen
        und Seminaren präsent.
      </Description> */}
    </ContentSection>
  );
}
