import styled from "styled-components/macro";
import { mediaTypes } from "../../styles/utils";

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  @media ${mediaTypes.sm} {
    display: flex;
  }
`;
