import React from "react";
import { useMediaQuery } from "@react-hook/media-query";

import { Title } from "./styles";

export const ResponsiveHeader = () => {
  const sm = useMediaQuery("only screen and (max-width: 798px)");
  return <>{sm && <Title>bc</Title>}</>;
};

export default ResponsiveHeader;
