import React from "react";

import {
  FooterWrapper,
  Logo,
  StyledUl,
  StyledLi,
  FlexItem,
} from "./styles";

import { StyledLink } from '../Navbar/styles';

import bpw from "../../assets/bpw.jpeg";

const Footer = () => {
  return (
    <FooterWrapper>
      <FlexItem>
        <StyledUl>
          <StyledLi>
            <StyledLink to={"/privacy"}>Datenschutz</StyledLink>
          </StyledLi>
          <StyledLi>
            <StyledLink to={"/contact"}>Kontakt</StyledLink>
          </StyledLi>
          <StyledLi>
            <StyledLink to={"/faq"}>FAQ</StyledLink>
          </StyledLi>
        </StyledUl>
      </FlexItem>
      <FlexItem>
        <Logo src={bpw} alt="logo" />
      </FlexItem>
    </FooterWrapper>
  );
};

export default Footer;
