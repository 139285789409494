import React from "react";

import location from "../../assets/location.svg";
import phone from "../../assets/phone.svg";
import fax from "../../assets/fax.svg";
import mail from "../../assets/mail.svg";

import {
  StyledCard,
  ContactFlex,
  Image,
  CardTitle,
  SubTitle,
  StyledA,
} from "./styles";

const Card = ({ children }) => {
  return (
    <StyledCard>
      <SubTitle>bovicare</SubTitle>
      <ContactFlex>
        <Image src={location} alt="location" />
        <CardTitle>Bernauer Allee 10</CardTitle>
      </ContactFlex>
      <ContactFlex>
        <Image src={location} alt="location" />
        <CardTitle>16321 Bernau OT Schönow</CardTitle>
      </ContactFlex>
      <ContactFlex>
        <Image src={phone} alt="phone" />
        <CardTitle>+49 (0)3338 - 34 14 590</CardTitle>
      </ContactFlex>
      <ContactFlex>
        <Image src={fax} alt="fax" />
        <CardTitle>+49 (0)3338 - 34 14 592</CardTitle>
      </ContactFlex>
      <ContactFlex>
        <Image src={mail} alt="mail" />
        <CardTitle>
          <StyledA href="kontakt@bovicare.de">kontakt@bovicare.de</StyledA>
        </CardTitle>
        {/* <Mail label="Write me an E-Mail" mailto="kontakt@bovicare.de" /> */}
      </ContactFlex>
    </StyledCard>
  );
};

export default Card;
