import styled from "styled-components/macro";
import { colors } from "../../styles/variables";
import { mediaTypes } from "../../styles/utils";
import { Link } from "react-router-dom";

export const FooterWrapper = styled.div`
  background-color: rgba(1,1,1,0.3);
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding-top: 1rem;
  @media ${mediaTypes.sm} {
    flex-direction: row;
    padding-top: 0;
  }
  @media ${mediaTypes.mdMax} {
    padding-bottom: 2rem;
  }
`;

export const Icon = styled.img`
  height: 1rem;
  margin-left: 0.5rem;
`;

export const ReferenceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1rem;
  background: ${colors.background};
  @media ${mediaTypes.sm} {
    margin-left: 0;
    margin-right: 1rem;
  }
`;

export const Logo = styled.img`
  height: 2.5rem;
  align-self: center;
  margin-right: 1.4rem;
  margin-left: 1.4rem;
`;

export const FlexItem = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 0;
  @media ${mediaTypes.sm} {
    padding-right: 2rem;
  }
`;

export const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;

export const StyledLi = styled.li`
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: .8rem!important;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

export const StyledUl = styled.ul`
  display: flex;
  list-style-type: none;
  padding: 0;
  @media ${mediaTypes.sm} {
  }
`;
