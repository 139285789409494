import React from "react";

import { Headline } from "../Landing/styles";
import ContentSection from "../../components/ContentSection";
import ResponsiveHeader from "../../components/RepsonsiveHeader";
import Paragraph from "../../components/Paragraph";

import { StyledOl, StyledLi } from "./styles";

export default function index() {
  return (
    <ContentSection>
      <ResponsiveHeader />
      <Headline>Datenschutzerklärung</Headline>
      <Paragraph>
        Wir freuen uns über Ihren Besuch auf unserer Webseite. Der Schutz Ihrer
        personenbezogenen Daten ist für uns ein wichtiges Anliegen, und wir
        möchten, dass Sie sich beim Besuch unserer Webseite sicher fühlen. Wir
        schützen Ihre Privatsphäre und Ihre personenbezogenen Daten. Wir
        verarbeiten Ihre personenbezogenen Daten in Übereinstimmung mit dem
        Inhalt dieser Datenschutzerklärung, den uns betreffenden
        landesspezifischen Datenschutzbestimmungen und der
        Datenschutz-Grundverordnung ("
        <a
          target="_blank"
          href="https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=CELEX:32016R0679" rel="noreferrer"
        >
          DSGVO"
        </a>
        ).
      </Paragraph>
      <StyledOl>
        <StyledLi>
          <strong>Name und Anschrift der verantwortlichen Stelle</strong>
          <br />
          Die bovicare GmbH, Hermannswerder Haus 14, 14473 Potsdam ("Wir") ist
          als Betreiber der Webseite{" "}
          <a href="https://www.bovicare.de">https://www.bovicare.de</a>{" "}
          ("Website") die verantwortliche Stelle im Sinne der DSGVO und anderer,
          landesspezifischer Datenschutzbestimmungen.
        </StyledLi>
        <StyledLi>
          <strong>Datenschutzbeauftragter</strong>
          <br />
          Sie können sich jederzeit mit allen datenschutzrechtlichen Belangen an
          unseren Datenschutzbeauftragten, Herrn Dr. Christian Fidelak, unter
          <a href="mailto:fidelak@bovicare.de">fidelak@bovicare.de</a> wenden.
        </StyledLi>
        <StyledLi>
          <strong>Personenbezogene Daten</strong>
          <br />
          Personenbezogene Daten sind alle Informationen, die sich auf eine
          identifizierte oder identifizierbare natürliche Person beziehen; als
          identifizierbar wird eine natürliche Person angesehen, die direkt oder
          indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem
          Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
          oder zu einem oder mehreren besonderen Merkmalen identifiziert werden
          kann, die Ausdruck der physischen, physiologischen, genetischen,
          psychischen, wirtschaftlichen, kulturellen oder sozialen Identität
          dieser natürlichen Person sind.
          <br />
          Zu den personenbezogenen Daten zählen auch Informationen über Ihre
          Nutzung unserer Webseite. In diesem Zusammenhang erheben wir
          personenbezogene Daten wie folgt von Ihnen: Informationen über Ihre
          Besuche unserer Webseite wie zum Beispiel Umfang des Datentransfers,
          den Ort, von dem aus Sie Daten von unserer Webseite abrufen sowie
          andere Verbindungsdaten und Dateien und sonstige Inhalte, die Sie
          abrufen. Dies geschieht in der Regel durch die Verwendung von Logfiles
          und Cookies. Weitere Informationen zu Logfiles und Cookies erhalten
          Sie weiter unten.
        </StyledLi>
        <StyledLi>
          <strong>
            Verwendungszweck und betroffene personenbezogene Daten
          </strong>
          Umfang und Art der Erhebung, Verarbeitung und Nutzung Ihrer Daten
          unterscheidet sich danach, ob Sie unseren Internetauftritt lediglich
          zum Abruf von allgemein verfügbaren Informationen besuchen oder
          darüber hinausgehende Leistungen in Anspruch nehmen:
          <ul>
            <li>
              <strong>Informatorische Nutzung</strong>
              <br />
              Für eine rein informatorische Nutzung unserer Webseite ist es
              grundsätzlich nicht erforderlich, dass Sie personenbezogene Daten
              angeben.
              <br />
              Vielmehr erfassen wir in diesem Fall beim Aufruf unserer Webseiten
              nur diejenigen Daten, die uns Ihr Internetbrowser automatisch
              übermittelt, wie etwa
              <ul>
                <li>die IP-Adresse Ihres Computers</li>
                <li>Datum und Uhrzeit des Seitenaufrufs</li>
                <li>
                  Ihren Browsertyp, die Browserversion sowie Ihre
                  Browsereinstellungen
                </li>
                <li>
                  das verwendete Betriebssystem (Windows, iOS, Linux etc.)
                </li>
                <li>die übertragene Datenmenge und Status von Übertragungen</li>
                <li>
                  von welcher Webseite aus der Zugriff auf unsere Seite erfolgte
                </li>
                <li>
                  sonstige ähnliche Daten und Informationen, welche der
                  Gefahrenabwehr im Falle von Angriffen auf unsere
                  informationstechnologischen Systeme dienen.
                </li>
              </ul>
              Die IP-Adresse Ihres Computers wird dabei nur für die Zeit Ihrer
              Nutzung der Webseite in sog. Logfiles gespeichert und im Anschluss
              daran unverzüglich gelöscht oder durch eine Kürzung anonymisiert.
              Die übrigen Daten werden für eine begrenzte Zeitdauer gespeichert.
              Wir verwenden diese Daten für den Betrieb unserer Webseite,
              insbesondere um Fehler der Webseite festzustellen und zu
              beseitigen, um die Auslastung der Webseite festzustellen und um
              Anpassungen oder Verbesserungen vorzunehmen.
            </li>
            <li>
              <strong>Nutzung bei Inanspruchnahme weiterer Leistungen </strong>
              <br />
              Soweit Sie über unsere Webseite weitere Leistungen unseres
              Unternehmens in Anspruch nehmen, ist es gegebenenfalls
              erforderlich, dass Sie hierzu personenbezogene Daten angeben.
              <br />
              Welche personenbezogenen Daten zur Leistungserbringung
              erforderlich sind, ergibt sich aus der jeweiligen Eingabemaske.
              <br />
              Wir werden ausschließlich diejenigen personenbezogenen Daten
              erheben, die zur Erfüllung der von Ihnen gewählten Leistung
              erforderlich sind, beispielsweise Ihre postalische Anschrift für
              Abrechnungszwecke bei Wahl der Zahlungsart "auf Rechnung".
              <br />
              Weitere Angaben können Sie freiwillig tätigen. Welche Angaben
              erforderlich und welche freiwillig sind, können Sie daran
              erkennen, dass erforderliche Angaben jeweils mit einem Sternchen
              (*) gekennzeichnet sind.
              <br />
              Die Erhebung, Verarbeitung und Nutzung Ihrer Daten erfolgt
              ausschließlich zu dem Zweck, die von Ihnen gewünschte Leistung zu
              erbringen.
            </li>
          </ul>
        </StyledLi>
        <StyledLi>
          <strong>Kontaktformular</strong>
          <br />
          Auf unserer Webseite bieten wir Ihnen die Möglichkeit, mittels eines
          Kontaktformulars mit uns der bovicare GmbH in Kontakt zu treten.
          <br />
          Der Empfänger Ihrer Nachricht ergibt sich aus der Beschreibung im
          Kontaktformular.
          <br />
          Die personenbezogenen Daten, die Sie im Rahmen dieser Kontaktanfrage
          angeben, werden nur für die Bearbeitung Ihrer Anfrage genutzt und
          unverzüglich vom Webserver gelöscht. Wir weisen darauf hin, dass die
          Nachrichten von den Empfängern der Nachrichten gegebenenfalls im
          Rahmen gesetzlich bestehender Aufbewahrungspflichten aufbewahrt
          werden. Eine Weitergabe an Dritte findet vorbehaltlich der
          vorstehenden Ausführungen nicht statt.
          <br />
          Die Übertragung der in die Kontaktformulare eingegebenen Daten erfolgt
          verschlüsselt.
        </StyledLi>
        <StyledLi>
          <strong>Cookies und Webanalysetools sowie Goolge Maps</strong>
          <br />
          <ul>
            <li>
              <strong>Verwendung von Cookies</strong>
              <br />
              Abhängig von den Einstellungen Ihres Browsers erheben wir außerdem
              Informationen über Ihre Nutzung unserer Webseite durch die
              Verwendung sog. Cookies. Dies sind kleine Textdateien, die auf
              Ihrem Datenträger gespeichert werden und die bestimmte
              Einstellungen und Daten zum Austausch mit unserem System über
              Ihren Browser speichern. Ein Cookie enthält in der Regel den Namen
              der Domain, von der die Cookie-Daten gesendet wurden sowie
              Informationen über das Alter des Cookies und ein alphanumerisches
              Identifizierungszeichen. Cookies ermöglichen unseren Systemen, das
              Gerät des Nutzers zu erkennen und eventuelle Voreinstellungen
              sofort verfügbar zu machen. Sobald ein Nutzer auf die Plattform
              zugreift, wird ein Cookie auf die Festplatte des Computers des
              jeweiligen Nutzers übermittelt. Cookies helfen uns, unsere
              Webseite zu verbessern und Ihnen einen besseren und noch mehr auf
              Sie zugeschnittenen Service anbieten zu können. Sie ermöglichen
              uns, Ihren Computer wieder zu erkennen, wenn Sie auf unsere
              Webseite zurückkehren und dadurch Informationen über Ihre
              bevorzugten Aktivitäten auf der Webseite zu speichern und so
              unsere Webseite an Ihren individuellen Interessen auszurichten.
              Dies kann zum Beispiel Werbung beinhalten, die Ihren persönlichen
              Interessen entspricht; und die Geschwindigkeit der Abwicklung
              Ihrer Anfragen zu beschleunigen.
              <br />
              In den von uns verwendeten Cookies werden lediglich die oben
              erläuterten Daten über Ihre Nutzung der Webseite gespeichert. Dies
              erfolgt nicht durch eine Zuordnung zu Ihnen persönlich, sondern
              durch Zuweisung einer Identifikationsnummer zu dem Cookie
              (»Cookie-ID«). Eine Zusammenführung der Cookie-ID mit Ihrem Namen,
              Ihrer IP-Adresse oder mit ähnlichen Daten, die eine Zuordnung des
              Cookies zu Ihnen ermöglichen würden, erfolgt nicht. Wie Sie den
              Einsatz von Cookies unterbinden können, erfahren Sie in der
              Hilfefunktion Ihres Browsers.
              <br />
              Sollten Sie eine Verwendung von Cookies nicht wünschen, können Sie
              Ihren Browser so einstellen, dass eine Speicherung von Cookies
              nicht akzeptiert wird. Bitte beachten Sie, dass Sie unsere
              Webseite in diesem Fall ggf. nur eingeschränkt oder gar nicht
              nutzen können.
              <br />
              Wenn Sie nur unsere eigenen Cookies, nicht aber die Cookies
              unserer Dienstleister und Partner akzeptieren wollen, können Sie
              die Einstellung in Ihrem Browser »Cookies von Drittanbietern
              blockieren« wählen.
            </li>
            <li>
              <strong>Keine Weitergabe Ihrer personenbezogenen Daten</strong>
              <br />
              Wir geben Ihre personenbezogenen Daten nicht an Dritte weiter; es
              sei denn, Sie haben in die Datenweitergabe eingewilligt, wir
              müssen Proben zur weiteren Diagnostik in ein anderes Labor
              verschicken oder wir sind aufgrund gesetzlicher Bestimmungen
              und/oder behördlicher oder gerichtlicher Anordnungen zu einer
              Datenweitergabe berechtigt oder verpflichtet. Dabei kann es sich
              insbesondere um eine Auskunftserteilung für Zwecke der
              Strafverfolgung, zur Gefahrenabwehr oder zur Durchsetzung
              geistiger Eigentumsrechte handeln.
              <br />
              <strong>
                Wir möchten Sie an dieser Stelle darauf hinweisen, dass
                Tierarztpraxen/-kliniken bei der Zusammenarbeit mit dem Labor
                keine Verträge zur Auftragserteilung nach Art. 28 DS-GVO
                benötigen. Ebenso ist keine
                Vertraulichkeits-/Geheimhaltungsvereinbarung zu treffen. Das
                Labor ist wie der Einsender selbst, ebenfalls
                Berufsgeheimnisträger nach §203 StGB und darüber hinaus
                natürlich auch verpflichtet die gesetzlichen Vorgaben des BDSG
                und der DS-GVO einzuhalten.
              </strong>
            </li>
          </ul>
        </StyledLi>
        <StyledLi>
          <strong>Datenschutz und Websites Dritter</strong>
          <br />
          Die Website kann Hyperlinks zu und von Websites Dritter enthalten.
          Wenn Sie einem Hyperlink zu einer dieser Websites folgen, beachten Sie
          bitte, dass wir keine Verantwortung oder Gewähr für fremde Inhalte
          oder die Einhaltung von Datenschutzbedingungen übernehmen können.
          Bitte vergewissern Sie sich über die jeweils geltenden
          Datenschutzbedingungen, bevor Sie personenbezogene Daten an diese
          Websites übermitteln.
        </StyledLi>
        <StyledLi>
          <strong>Datensicherheit</strong>
          <br />
          Alle Informationen, die Sie an uns übermitteln, werden auf Servern
          innerhalb der Europäischen Union gespeichert. Wir sichern unsere
          Webseite und sonstigen Systeme jedoch durch technische und
          organisatorische Maßnahmen gegen Verlust, Zerstörung, Zugriff,
          Veränderung oder Verbreitung Ihrer Daten durch unbefugte Personen ab.
          Insbesondere werden Ihre personenbezogenen Daten bei uns verschlüsselt
          übertragen.
        </StyledLi>
        <StyledLi>
          <strong>Ihre Rechte</strong>
          <br />
          Sie haben das Recht, von uns
          <ul>
            <li>
              unentgeltlich <strong>Auskunft</strong> über die von uns über Ihre
              Person gespeicherten personenbezogenen Daten zu erhalten
              (Auskunftsrecht)
            </li>
            <li>
              eine <strong>Bestätigung</strong> darüber zu verlangen, ob wir Sie
              betreffende personenbezogene Daten verarbeiten (Recht auf
              Bestätigung)
            </li>
            <li>
              von uns zu verlangen, dass die Sie betreffenden personenbezogenen
              Daten von uns unverzüglich gelöscht werden, sofern deren
              Verarbeitung nicht mehr erforderlich ist und auch die weiteren
              Voraussetzungen der DSGVO für eine <strong>Löschung</strong>{" "}
              erfüllt sind (Recht auf Löschung)
            </li>
            <li>
              die sofortige <strong>Berichtigung und Vervollständigung</strong>{" "}
              Sie betreffender unrichtiger personenbezogenen Daten zu verlangen
              (Recht auf Berichtigung)
            </li>
            <li>
              die <strong>Einschränkung</strong> der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen (Recht auf Einschränkung der
              Verarbeitung)
            </li>
            <li>
              die Sie betreffenden personenbezogenen Daten in einem
              strukturierten, gängigen und maschinenlesbaren Format zu erhalten
              (Recht auf <strong>Datenübertragbarkeit</strong>)
            </li>
            <li>
              der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen
              (Recht auf <strong>Widerspruch</strong>)
            </li>
            <li>
              Sie haben das Recht, nicht einer ausschließlich auf einer
              automatisierten Verarbeitung - einschließlich Profiling -
              beruhenden Entscheidung unterworfen zu werden, die Ihnen gegenüber
              rechtliche Wirkung entfaltet oder Sie in ähnlicher Weise
              beeinträchtigt (
              <strong>Recht auf Entscheidung im Einzelfall</strong>)
            </li>
            <li>
              weiter haben Sie das Recht, jederzeit Ihre Einwilligung in die
              Verarbeitung Ihrer personenbezogenen Daten mit Wirkung für die
              Zukunft zu <strong>widerrufen</strong>.
            </li>
            <br />
            Für weitergehende Informationen zu Ihren Rechten wenden Sie sich
            bitte an unseren Datenschutzbeauftragten.
          </ul>
        </StyledLi>
        <StyledLi>
          <strong>
            Löschung und Sperrung von personenbezogenen Daten nach
            Zweckerfüllung
          </strong>
          <br />
          Wir verarbeiten und speichern personenbezogene Daten nur so lange, wie
          dies zur Zweckerfüllung erforderlich ist längstens jedoch bis zum
          Ablauf der gesetzlichen Aufbewahrungs- und Speicherfristen.
          <br />
          Bei Zweckerfüllung und Ablauf der gesetzlichen Aufbewahrungs- und
          Speicherfristen werden die personenbezogenen Daten entsprechend den
          datenschutzrechtlichen Vorgaben gelöscht oder gesperrt.
        </StyledLi>
        <StyledLi>
          <strong>Beschwerderecht</strong>
          <br />
          Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
          gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
          einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
          Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen
          Verstoßes, zu, wenn Sie der Ansicht sind, dass die Verarbeitung der
          Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.
          <br />
          <br />
          Zuständige federführende Aufsichtsbehörde für den Datenschutz nach
          Art. 56 DSGVO bei Beschwerden ist:
          <br />
          Bayerisches Landesamt für Datenschutzaufsicht
          <br />
          Promenade 27 (Schloss)
          <br />
          91522 Ansbach
          <br />
          Tel.: +49 981 53 1300
          <br />
          Fax: +49 981 53 98 1300
          <br />
          E-Mail:{" "}
          <a href="mailto:poststelle@lda.bayern.de">poststelle@lda.bayern.de</a>
        </StyledLi>
        <br />
      </StyledOl>
    </ContentSection>
  );
}
