import styled from 'styled-components';
import { colors } from "../../styles/variables";

export const Title = styled.h1`
  width: 50px;
  height: 50px;
  padding: 5px;
  border-radius: 5px;
  font-size: 45px;
  color: white;
  font-weight: bold;
  background-color: ${colors.bovi};
`;
