import React from "react";
import { useMediaQuery } from "@react-hook/media-query";

import Modal from "../../components/Modal";
import ContentSection from "../../components/ContentSection";
import { Headline, Teaser, Title } from "./styles";
import { FooterImage } from "../../styles";

import { CenterSection } from "../styles";

export const Landing = () => {
  const sm = useMediaQuery("only screen and (max-width: 798px)");
  const cowQuery = useMediaQuery("only screen and (min-width: 798px)");
  return (
    <>
      <ContentSection>
        {sm && <Title>bc</Title>}
        <Teaser>
          <Headline>
            Willkommen bei{" "}
            <span style={{ fontSize: "2.5rem", fontWeight: "bold" }}>
              bovicare
            </span>{" "}
            <br /> Ihrem Partner rund um die Milchviehhaltung
          </Headline>
        </Teaser>
        <Teaser>
          <Headline>
            Labordiagnostik, tierärztliche Beratung und Betreuung zur
            Unterstützung der Produktion hochwertiger und gesunder Milch
          </Headline>
        </Teaser>
        <CenterSection>
          <Modal />
        </CenterSection>
        {/* <Headline>bovicare - for the cowside of life</Headline> */}
      </ContentSection>
      {cowQuery && <FooterImage />}
    </>
  );
};

export default Landing;
