import styled from "styled-components";
import { mediaTypes } from "../../styles/utils";

export const DownloadContentSection = styled.div`
  display: flex;
  flex-direction: column;
  @media ${mediaTypes.md}{
    flex-direction: row;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 35px;

`;

export const MidContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 35px;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 35px;
`;

export const DownloadElement = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  border-bottom: 1px solid white;
`;

export const DownloadTitle = styled.h3`
  color: white;
  padding-right: 25px;
`;

export const DownloadA = styled.a`
  text-decoration: none;
`;