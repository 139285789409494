import React from "react";

import { Headline } from "../Landing/styles";
import ContentSection from "../../components/ContentSection";
import ResponsiveHeader from "../../components/RepsonsiveHeader";

export default function index() {
  return (
    <ContentSection>
      <ResponsiveHeader />
      <Headline>Team</Headline>
    </ContentSection>
  );
}
